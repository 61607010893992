<template>
  <section class="message-block">
    <h2>Messages</h2>
    <ul class="message-stack">
      <li v-for="msg in activeMessages" :key="msg.id">
        <p class="message">{{ msg.message }}</p>
        <div class="message-details">
          <p><strong>{{ msg.name }}</strong><br><em>{{ msg.createdAt }}</em></p>
          <div class="message-action">
            <a v-show="msg.email" :href="'mailto:'+msg.email+'?subject=Hello! Messages replay from petemcbride.ca'" class="icon-button mrg-left">
              <span><i class="fas fa-reply"></i></span>
            </a>
            <button class="icon-button mrg-left" @click="handleDelete(msg.id)">
              <span><i class="fas fa-trash-alt"></i></span>
            </button>
            <button v-show="!msg.archived" class="icon-button mrg-left" @click="handleArchive(msg.id)">
              <span><i class="fas fa-folder-open"></i></span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div>
      <p class="no-messages"><span><i class="fas fa-envelope"></i></span> No messages</p>
    </div>
  </section>
</template>

<script>
import useCollection from '@/composables/useCollection'
import { computed, ref } from 'vue'

export default {
  props: ['messages'],
  setup(props) {
    const { deleteDoc, updateDoc } = useCollection('messages')

    const activeMessages = computed(() => {
      if (props.messages) {
        return props.messages.filter((msg) => !msg.archived)
      }
    })

    const handleDelete = async (id) => {
      await deleteDoc(id)
    }

    // archive message by updating message doc, archived element to true
    const handleArchive = async (id) => {
      await updateDoc(id, { archived: true })
    }

    return { handleDelete, handleArchive, activeMessages }
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/partials/_global.scss';

.message-block {
  background-color: $prime;
  padding: 24px;
}
.message-stack {
  margin: 12px 0;
  li {
    padding: 12px 20px;
    background-color: rgba($prime-shdw, 0.4);
    border-radius: 10px;
    margin-bottom: 16px;
    p.message {
      color: $txt-dark;
      font-size: 2rem;
      line-height: 1.2;
    }
    div.message-details {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      strong {
        color: $txt-dark;
        font-family: 'black';
        font-size: 1.6rem;
      }
      em {
        color: $prime-dark;
        font-family: 'regular';
        font-style: normal;
        font-size: 1.4rem;
      }
    }
  }
}
.no-messages {
  margin: 20px;
}
@media screen and (min-width: 680px) {
  .message-block {
    padding: 24px 20% 48px;
  }
}
</style>