<template>
    <div class="content-block">
      <section class="block-full">
        <MessageList :messages="messages" />
      </section>
      <Footer />
    </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import useCollection from '@/composables/useCollection'
import MessageList from '@/components/lists/MessageList.vue'
import Footer from '@/components/Footer.vue'
import { formatDistanceToNow } from 'date-fns'
import { computed } from 'vue'

export default {
  components: { MessageList, Footer },
  setup() {
    const { documents } = getCollection('messages')
    const { addDoc } = useCollection('messages')

    const messages = computed(() => {
      if (documents.value) {
        return documents.value.map((doc) => {
          let time = formatDistanceToNow(doc.createdAt.toDate())
          return { ...doc, createdAt: time }
        })
      }
    })

    return { messages }
  },
}
</script>

<style>
</style>